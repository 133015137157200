import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getHistories = async (data) => {
  const response = await $authApi.get(apiRoutes.HISTORIES, {
    params: data,
  });
  return response?.data;
};
const getHistory = async (id) => {
  const response = await $authApi.get(apiRoutes.HISTORY, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editHistory = async (data) => {
  const response = await $authApi.put(apiRoutes.HISTORIES, data);
  return response?.data;
};
const deleteHistory = async (id) => {
  const response = await $authApi.delete(apiRoutes.HISTORIES, {
    data: { id },
  });
  return response?.data;
};

export { getHistories, getHistory, editHistory, deleteHistory };
