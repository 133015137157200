import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getTasks = async (data) => {
  const response = await $authApi.get(apiRoutes.TASKS, {
    params: data,
  });

  return response?.data;
};

const getTask = async (data) => {
  const response = await $authApi.get(apiRoutes.TASK, {
    params: data,
  });

  return response?.data;
};

const editTask = async (data) => {
  const response = await $authApi.put(apiRoutes.TASKS, data);
  return response?.data;
};

const createTask = async (data) => {
  const response = await $authApi.post(apiRoutes.TASKS, data);
  return response?.data;
};

const deleteTask = async (id) => {
  const response = await $authApi.delete(apiRoutes.TASKS, {
    data: { id },
  });
  return response?.data;
};

export {
  getTasks,
  getTask,
  editTask,
  deleteTask,
  createTask,
};
