import React from "react";
import { Badge } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import Footer from "./Footer";

const AdminMenu = () => {
  const role = useSelector((state) => state.auth.user.role);
  const notification = useSelector((state) => state.notification);
  return (
    <div className="w-100">
      <Link
        to="/"
        className="fs-12 fw-7 p-2 py-3 d-flex align-items-center logo"
      >
        <img src="/logo.png" height={40} />
        <span className="ms-3 fw-7">Gikami</span>
      </Link>
      <nav className="account-menu position-sticky top-1 mb-4">
        <ul>
          <li>
            <NavLink to="/" end>
              <span className="d-flex flex-row align-items-center">
                <svg
                  className="icon"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M20.9603 6.0506C20.9106 7.03855 19.9709 7.40975 19.242 6.741C18.9106 6.4369 18.5138 6.06255 18.0418 5.60285C16.9756 6.5731 15.322 8.08585 13.3506 9.91645C12.8329 10.3972 11.992 10.3435 11.5397 9.8007L11.5394 9.8003C10.6518 8.73835 9.75755 7.6807 8.8322 6.6514C7.7509 7.4453 5.81 8.95825 3.88389 10.8844C3.39573 11.3725 2.60427 11.3725 2.11612 10.8844C1.62796 10.3962 1.62796 9.60475 2.11612 9.1166C4.00573 7.227 6.079 5.4545 8.30255 3.96314L8.306 3.96082C8.30685 3.96027 8.3066 3.96042 8.3066 3.96042C8.8024 3.6299 9.46255 3.69527 9.8839 4.1166C10.8519 5.08455 11.7265 6.14925 12.6118 7.19265C14.1169 5.80065 15.3848 4.64087 16.274 3.8314C15.8705 3.41506 15.5362 3.06006 15.26 2.75898C14.591 2.02997 14.9624 1.08998 15.9506 1.04025C17.2115 0.976795 18.5055 0.939456 19.7511 1.17232C20.3119 1.27718 20.7233 1.68863 20.8281 2.24948C21.061 3.49521 21.0238 4.78949 20.9603 6.0506Z" />
                  <g opacity="0.32">
                    <path d="M2.60946 22.9843C1.77292 22.9631 1.13928 22.4599 1.07089 21.6259C1.02993 21.1264 1 20.4398 1 19.5C1 18.5602 1.02993 17.8736 1.07089 17.3741C1.13928 16.5401 1.77292 16.0369 2.60946 16.0157C2.97545 16.0064 3.43306 16 4 16C4.56694 16 5.02455 16.0064 5.39055 16.0157C6.2271 16.0369 6.8607 16.5401 6.9291 17.3741C6.97005 17.8736 7 18.5602 7 19.5C7 20.4398 6.97005 21.1264 6.9291 21.6259C6.8607 22.4599 6.2271 22.9631 5.39055 22.9843C5.02455 22.9936 4.56694 23 4 23C3.43306 23 2.97545 22.9936 2.60946 22.9843Z" />
                    <path d="M18.8455 22.9792C17.8709 22.9408 17.1875 22.2557 17.1243 21.2823C17.0588 20.2731 17 18.6337 17 16C17 13.3663 17.0588 11.727 17.1243 10.7177C17.1875 9.74435 17.8709 9.0592 18.8455 9.02075C19.1671 9.00805 19.549 9 20 9C20.451 9 20.8329 9.00805 21.1545 9.02075C22.1291 9.0592 22.8125 9.74435 22.8757 10.7177C22.9412 11.727 23 13.3663 23 16C23 18.6337 22.9412 20.2731 22.8757 21.2823C22.8125 22.2557 22.1291 22.9408 21.1545 22.9792C20.8329 22.992 20.451 23 20 23C19.549 23 19.1671 22.992 18.8455 22.9792Z" />
                    <path d="M10.7766 22.9832C9.8427 22.9548 9.162 22.3419 9.0949 21.41C9.0422 20.6775 9 19.5936 9 18C9 16.4064 9.0422 15.3225 9.0949 14.59C9.162 13.6581 9.8427 13.0453 10.7766 13.0169C11.1122 13.0067 11.5163 13 12 13C12.4837 13 12.8878 13.0067 13.2234 13.0169C14.1573 13.0453 14.838 13.6581 14.9051 14.59C14.9578 15.3225 15 16.4064 15 18C15 19.5936 14.9578 20.6775 14.9051 21.41C14.838 22.3419 14.1573 22.9548 13.2234 22.9832C12.8878 22.9934 12.4837 23 12 23C11.5163 23 11.1122 22.9934 10.7766 22.9832Z" />
                  </g>
                </svg>
                <span>Аналитика</span>
              </span>
            </NavLink>
          </li>
          {role != 4 && (
            <>
              <li>
                <NavLink to="users">
                  <span className="d-flex flex-row align-items-center">
                    <svg
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.32"
                        d="M2.28099 19.6575C2.36966 20.5161 2.93261 21.1957 3.77688 21.3755C5.1095 21.6592 7.6216 22 12 22C16.3784 22 18.8905 21.6592 20.2232 21.3755C21.0674 21.1957 21.6304 20.5161 21.719 19.6575C21.8505 18.3844 22 16.0469 22 12C22 7.95305 21.8505 5.6156 21.719 4.34251C21.6304 3.48389 21.0674 2.80424 20.2231 2.62451C18.8905 2.34081 16.3784 2 12 2C7.6216 2 5.1095 2.34081 3.77688 2.62451C2.93261 2.80424 2.36966 3.48389 2.28099 4.34251C2.14952 5.6156 2 7.95305 2 12C2 16.0469 2.14952 18.3844 2.28099 19.6575Z"
                      />
                      <path d="M13.9382 13.8559C15.263 13.1583 16.1663 11.768 16.1663 10.1666C16.1663 7.8655 14.3008 6 11.9996 6C9.69841 6 7.83291 7.8655 7.83291 10.1666C7.83291 11.768 8.73626 13.1584 10.0612 13.856C8.28691 14.5319 6.93216 16.1092 6.51251 18.0529C6.45446 18.3219 6.60246 18.5981 6.87341 18.6471C7.84581 18.8231 9.45616 19 12.0006 19C14.545 19 16.1554 18.8231 17.1278 18.6471C17.3977 18.5983 17.5454 18.3231 17.4876 18.0551C17.0685 16.1103 15.7133 14.5321 13.9382 13.8559Z" />
                    </svg>
                    <span>Пользователи</span>
                  </span>
                </NavLink>
              </li>
              <li>
                <NavLink to="members">
                  <span className="d-flex flex-row align-items-center">
                    <svg
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="Subtract"
                        opacity="0.32"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755ZM18.1663 8.16665C18.1663 9.76795 17.263 11.1582 15.9382 11.8559C17.7133 12.5321 19.0685 14.1103 19.4876 16.0551C19.5454 16.3231 19.3977 16.5983 19.1278 16.6471C18.1554 16.8231 16.545 17 14.0006 17C11.4562 17 9.84581 16.8231 8.87341 16.6471C8.60246 16.5981 8.45446 16.3219 8.51251 16.0529C8.93216 14.1092 10.2869 12.5319 12.0612 11.856C10.7363 11.1584 9.83291 9.768 9.83291 8.16665C9.83291 5.8655 11.6984 4 13.9996 4C16.3008 4 18.1663 5.8655 18.1663 8.16665Z"
                        fill="#637381"
                      />
                      <path
                        id="shape"
                        d="M12.4382 14.8559C13.763 14.1583 14.6663 12.768 14.6663 11.1666C14.6663 8.8655 12.8008 7 10.4996 7C8.19841 7 6.33291 8.8655 6.33291 11.1666C6.33291 12.768 7.23626 14.1584 8.56116 14.856C6.78691 15.5319 5.43216 17.1092 5.01251 19.0529C4.95446 19.3219 5.10246 19.5981 5.37341 19.6471C6.34581 19.8231 7.95616 20 10.5006 20C13.045 20 14.6554 19.8231 15.6278 19.6471C15.8977 19.5983 16.0454 19.3231 15.9876 19.0551C15.5685 17.1103 14.2133 15.5321 12.4382 14.8559Z"
                        fill="#637381"
                      />
                    </svg>

                    <span>Команда</span>
                  </span>
                </NavLink>
              </li>
            </>
          )}
          {role != 3 && role != 2 && (
            <>
              <li className="mt-4">
                <p className="nav-label">Контент</p>
              </li>
              {/* <li>
                <NavLink to="catalog/games">
                  <span className="d-flex flex-row align-items-center">
                    <svg
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.32"
                        d="M17.2798 4.5H6.7202C5.77169 4.5 5 5.06057 5 5.75042C5 6.43943 5.77169 7 6.7202 7H17.2798C18.2283 7 19 6.43943 19 5.75042C19 5.06054 18.2283 4.5 17.2798 4.5Z"
                      />
                      <path d="M17.2798 17H6.7202C5.77169 17 5 17.5606 5 18.2504C5 18.9394 5.77169 19.5 6.7202 19.5H17.2798C18.2283 19.5 19 18.9394 19 18.2504C19 17.5606 18.2283 17 17.2798 17Z" />
                      <path d="M17.2798 10.75H6.7202C5.77169 10.75 5 11.3106 5 12.0004C5 12.6894 5.77169 13.25 6.7202 13.25H17.2798C18.2283 13.25 19 12.6894 19 12.0004C19 11.3105 18.2283 10.75 17.2798 10.75Z" />
                    </svg>
                    <span>Книги</span>
                  </span>
                </NavLink>
              </li> */}
              <li>
                <NavLink to="catalog/books">
                  <span className="d-flex flex-row align-items-center">
                    <svg
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="shape"
                        opacity="0.32"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M20.9114 8.22695C19.4717 8.5891 17.7718 8.61315 16.3035 8.1646C15.6828 7.97495 15.1988 7.4914 14.9893 6.8771C14.4674 5.34677 14.3385 3.47362 14.722 2.0318C13.9279 2.01186 13.0248 2 12 2C8.51575 2 6.43945 2.13682 5.26285 2.26379C4.39116 2.35785 3.71902 2.94826 3.5558 3.80967C3.30175 5.15055 3 7.65725 3 12C3 16.3428 3.30175 18.8494 3.5558 20.1903C3.71902 21.0518 4.39116 21.6422 5.26285 21.7362C6.43945 21.8631 8.51575 22 12 22C15.4843 22 17.5606 21.8631 18.7372 21.7362C19.6089 21.6422 20.281 21.0518 20.4442 20.1903C20.6982 18.8494 21 16.3428 21 12C21 10.5445 20.9661 9.2952 20.9114 8.22695ZM8 13C7.4477 13 7 12.5523 7 12C7 11.4477 7.4477 11 8 11H12C12.5523 11 13 11.4477 13 12C13 12.5523 12.5523 13 12 13H8ZM8 17.5C7.4477 17.5 7 17.0523 7 16.5C7 15.9477 7.4477 15.5 8 15.5H15C15.5523 15.5 16 15.9477 16 16.5C16 17.0523 15.5523 17.5 15 17.5H8Z"
                      />

                      <path d="M7 16.5C7 17.0523 7.4477 17.5 8 17.5H15C15.5523 17.5 16 17.0523 16 16.5C16 15.9477 15.5523 15.5 15 15.5H8C7.4477 15.5 7 15.9477 7 16.5Z" />
                      <path d="M7 12C7 12.5523 7.4477 13 8 13H12C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11H8C7.4477 11 7 11.4477 7 12Z" />
                      <path d="M20.9114 8.22695C19.4717 8.5891 17.7718 8.61315 16.3036 8.1646C15.6828 7.97495 15.1988 7.4914 14.9893 6.8771C14.4674 5.34675 14.3384 3.47357 14.722 2.03174C14.722 2.03174 15.9461 2.49994 18.1961 4.74994C20.4461 6.99995 20.9114 8.22695 20.9114 8.22695Z" />
                    </svg>
                    <span>Книги</span>
                  </span>
                </NavLink>
              </li>
            </>
          )}
          {role != 4 && (
            <>
              <li className="mt-4">
                <p className="nav-label">Финансы</p>
              </li>
              <li>
                <NavLink to="finance/transactions">
                  <span className="d-flex flex-row align-items-center">
                    <svg
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        id="shape"
                        opacity="0.32"
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3.77688 21.3755C2.93261 21.1957 2.36966 20.5161 2.28099 19.6575C2.14952 18.3844 2 16.0469 2 12C2 7.95305 2.14952 5.6156 2.28099 4.34251C2.36966 3.48389 2.93261 2.80424 3.77688 2.62451C5.1095 2.34081 7.6216 2 12 2C16.3784 2 18.8905 2.34081 20.2231 2.62451C21.0674 2.80424 21.6304 3.48389 21.719 4.34251C21.8505 5.6156 22 7.95305 22 12C22 16.0469 21.8505 18.3844 21.719 19.6575C21.6304 20.5161 21.0674 21.1957 20.2232 21.3755C18.8905 21.6592 16.3784 22 12 22C7.6216 22 5.1095 21.6592 3.77688 21.3755ZM17 8.37267V15.236C16.9936 15.5958 16.8463 15.9387 16.59 16.1909C16.3336 16.4431 15.9886 16.5844 15.6293 16.5844C15.27 16.5844 14.925 16.4431 14.6686 16.1909C14.4123 15.9387 14.265 15.5958 14.2586 15.236V11.6671L9.3496 16.5953C8.81262 17.1344 7.94025 17.135 7.40262 16.5965C6.86689 16.06 6.86528 15.1919 7.40088 14.6553C9.12404 12.9289 11.6962 10.3625 12.3396 9.74535H8.77583C8.41654 9.73891 8.07415 9.59146 7.82234 9.33474C7.57053 9.07801 7.42944 8.73254 7.42944 8.37267C7.42944 8.01281 7.57053 7.66734 7.82234 7.41061C8.07415 7.15389 8.41654 7.00644 8.77583 7H15.6293C15.9925 7.00109 16.3405 7.14606 16.5973 7.40325C16.8542 7.66044 16.9989 8.00895 17 8.37267Z"
                      />
                      <path
                        id="shape_2"
                        d="M17 15.236V8.37267C16.9989 8.00895 16.8542 7.66044 16.5973 7.40325C16.3405 7.14606 15.9925 7.00109 15.6293 7H8.77583C8.41654 7.00644 8.07415 7.15389 7.82234 7.41061C7.57053 7.66734 7.42944 8.01281 7.42944 8.37267C7.42944 8.73254 7.57053 9.07801 7.82234 9.33474C8.07415 9.59146 8.41654 9.73891 8.77583 9.74535H12.3396C11.6962 10.3625 9.12404 12.9289 7.40088 14.6553C6.86528 15.1919 6.86689 16.06 7.40262 16.5965C7.94025 17.135 8.81262 17.1344 9.3496 16.5953L14.2586 11.6671V15.236C14.265 15.5958 14.4123 15.9387 14.6686 16.1909C14.925 16.4431 15.27 16.5844 15.6293 16.5844C15.9886 16.5844 16.3336 16.4431 16.59 16.1909C16.8463 15.9387 16.9936 15.5958 17 15.236Z"
                        fill="white"
                      />
                    </svg>
                    <span>Транзакции</span>
                  </span>
                </NavLink>
              </li>
            </>
          )}
          {role != 4 && role != 3 && (
            <>
              <li className="mt-4">
                <p className="nav-label">Обратная связь</p>
              </li>
              <li>
                <NavLink to="dialogs">
                  <span className="d-flex flex-row align-items-center">
                    <svg
                      className="icon"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        opacity="0.32"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.12319 2.24369C5.3177 2.12083 7.30475 2 10.5 2C13.6952 2 15.6823 2.12083 16.8768 2.24369C17.8972 2.34863 18.6398 3.10549 18.7572 4.12444C18.8797 5.18775 19 6.7933 19 9C19 11.2067 18.8797 12.8123 18.7572 13.8756C18.6398 14.8945 17.8973 15.6514 16.877 15.7563C15.822 15.8648 14.1489 15.9717 11.571 15.9952C11.1714 15.9989 10.7875 16.1592 10.507 16.4437L8.816 18.1584C8.08185 18.9029 6.8125 18.4707 6.6853 17.4328L6.55145 16.3414C6.52175 16.099 6.3197 15.9137 6.0759 15.9011C5.26545 15.859 4.62502 15.8079 4.12397 15.7564C3.10365 15.6515 2.36022 14.8945 2.24278 13.8756C2.12023 12.8123 2 11.2067 2 9C2 6.7933 2.12023 5.18775 2.24278 4.12444C2.36022 3.10549 3.10287 2.34863 4.12319 2.24369ZM7 6C6.4477 6 6 6.4477 6 7C6 7.5523 6.4477 8 7 8H14C14.5523 8 15 7.5523 15 7C15 6.4477 14.5523 6 14 6H7ZM7 10.5C6.4477 10.5 6 10.9477 6 11.5C6 12.0523 6.4477 12.5 7 12.5H11C11.5523 12.5 12 12.0523 12 11.5C12 10.9477 11.5523 10.5 11 10.5H7Z"
                      />
                      <path d="M5.99902 7C5.99902 6.4477 6.44672 6 6.99902 6H13.999C14.5513 6 14.999 6.4477 14.999 7C14.999 7.5523 14.5513 8 13.999 8H6.99902C6.44672 8 5.99902 7.5523 5.99902 7Z" />
                      <path d="M5.99902 11.5C5.99902 10.9477 6.44672 10.5 6.99902 10.5H10.999C11.5513 10.5 11.999 10.9477 11.999 11.5C11.999 12.0523 11.5513 12.5 10.999 12.5H6.99902C6.44672 12.5 5.99902 12.0523 5.99902 11.5Z" />
                      <path d="M10.0257 16.9308L10.506 16.4437C10.7866 16.1592 11.1705 15.9989 11.57 15.9952C14.1479 15.9717 15.821 15.8648 16.876 15.7563C17.8963 15.6514 18.6388 14.8945 18.7562 13.8755C18.8363 13.1809 18.9154 12.2547 18.9606 11.0808C19.4674 11.1109 19.8664 11.1462 20.1773 11.1811C20.8028 11.2515 21.2614 11.7033 21.3392 12.3279C21.4206 12.9808 21.499 13.9995 21.499 15.5C21.499 17.0005 21.4206 18.0192 21.3392 18.6721C21.2614 19.2967 20.8021 19.7486 20.1766 19.8189C19.7585 19.8659 19.1808 19.9136 18.3962 19.9483C18.1641 19.9585 17.9673 20.1252 17.9223 20.3531L17.7516 21.219C17.6396 21.7868 16.9538 22.0192 16.5197 21.6364L15.0712 20.3589C14.8029 20.1223 14.4575 19.9901 14.0999 19.9816C12.7849 19.9504 11.9009 19.884 11.3222 19.819C10.6967 19.7488 10.2366 19.2967 10.1588 18.6721C10.1066 18.2528 10.0555 17.6826 10.0257 16.9308Z" />
                    </svg>
                    <span>Чат</span>
                  </span>
                  {notification?.message > 0 && (
                    <Badge className="ms-2" bg="danger">
                      {notification.message}
                    </Badge>
                  )}
                </NavLink>
              </li>
            </>
          )}
        </ul>
        <Footer />
      </nav>
    </div>
  );
};

export default AdminMenu;
