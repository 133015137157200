import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getWithdrawals = async (data) => {
  const response = await $authApi.get(apiRoutes.WITHDRAWALS, {
    params: data,
  });
  return response?.data;
};
const getWithdrawal = async (id) => {
  const response = await $authApi.get(apiRoutes.WITHDRAWAL, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editWithdrawal = async (data) => {
  const response = await $authApi.put(apiRoutes.WITHDRAWALS, data);
  return response?.data;
};
const deleteWithdrawal = async (id) => {
  const response = await $authApi.delete(apiRoutes.WITHDRAWALS, {
    data: { id },
  });
  return response?.data;
};

export {
  getWithdrawals,
  getWithdrawal,
  editWithdrawal,
  deleteWithdrawal
};
