import React, { useCallback } from "react";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import { createBook } from "../../services/book";
import CustomEditor from "../../components/editor/CustomEditor";
import Input from "../../components/UI/Input";
import Button from "../../components/UI/Button";

const BookCreate = () => {
  const navigate = useNavigate();

  const {
    control,
    register,
    formState: { errors, isValid },
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    createBook(data)
      .then(() => {
        NotificationManager.success("Новость успешно создана");
        navigate(-1);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, [data]);

  return (
    <>
      <Meta title="Создать новость" />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to="/catalog/articles"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button disabled={!isValid} onClick={() => onSubmit()}>
          Сохранить изменения
        </Button>
      </div>
      <h3 className="mb-4">Создать новость</h3>
      <div className="mb-3">
        <Input
          label="Название"
          name="title"
          placeholder="Заголовок"
          errors={errors}
          register={register}
          validation={{
            required: "Обязательное поле",
          }}
        />
      </div>
      <CustomEditor
        content={data.content}
        onChange={(e) => setValue("content", e)}
      />
    </>
  );
};

export default BookCreate;
