import React from "react";
import { NavLink } from "react-router-dom";

const MenuCatalog = () => {
  return (
    <div className="w-100 account-menu-sub-bg">
      <nav className="account-menu-sub">
        <ul>
          <li>
            <NavLink to="games" end>
              Игры
            </NavLink>
          </li>
          <li>
            <NavLink to="params" disabled>
              Параметры
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default MenuCatalog;
