import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row } from "react-bootstrap";
import {
  IoChatboxOutline,
  IoCloseOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import socket from "../../config/socket";
import { deleteOrder, getOrders } from "../../services/order";
import { updateNotification } from "../../store/reducers/notificationSlice";

const Orders = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useDispatch();
  const inputRef = createRef();

  const orderNotification = useSelector((state) => state.notification.order);

  const [orders, setOrders] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    isShow: false,
    id: false,
  });

  const orderColumns = [
    {
      name: "Пользователь",
      cell: (row) => (
        <Link to={`/user/${row?.user?.id}`}>{row?.user?.nickname}</Link>
      ),
    },
    {
      name: "id",
      selector: "id",
    },
    {
      name: "Сумма",
      selector: "total",
    },
    {
      name: "Дата",
      selector: "createdAt",
      showDesc: true,
      showDescIcon: false,
      cell: (row) => (
        <>
          <span className="me-1">
            {moment(row.createdAt).format("DD.MM.YYYY")}
          </span>
          <span className="fw-7">{moment(row.createdAt).format("kk:mm")}</span>
        </>
      ),
    },
    {
      align: "center",
      name: <IoChatboxOutline size={18} />,
      cell: (row) => (row?.comment ? row.comment : "-"),
    },
    {
      width: "130px",
      name: "Статус",
      align: "center",
      cell: (row) =>
        !row.status || row.status === "process" ? (
          <Badge bg="secondary">В ожидании</Badge>
        ) : row.status === "ok" ? (
          <Badge bg="success">Выполнено</Badge>
        ) : row.status === "canceled" ? (
          <Badge bg="danger">Отменено</Badge>
        ) : (
          row.status === "refund" && <Badge bg="danger">Возврат</Badge>
        ),
    },
    // {
    //   width: "80px",
    //   selector: "action",
    //   align: "right",
    //   cell: (row) => {
    //     return (
    //       <>
    //         <div className="d-flex align-items-center">
    //           <Link to={"/order/" + row.id} className="me-2">
    //             <IoCreateOutline size={22} />
    //           </Link>
    //         </div>
    //       </>
    //     );
    //   },
    // },
  ];

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0 ? `Выбрано ${selected.length}` : "Сделки"}
            </h5>
          </div>
          <div className="d-flex align-items-center">
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={4}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате добавления: новые", value: "" },
                  { title: "По дате добавления: старые", value: "createold" },
                  { title: "Стоимость: больше", value: "pricemore" },
                  { title: "Стоимость: меньше", value: "priceless" },
                ]}
                value={searchParams.get("sort") ?? ""}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={8}>
              <Input
                ref={inputRef}
                placeholder="Найти"
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                defaultValue={searchParams.get("text")}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  const getData = useCallback(async () => {
    dispatch(updateNotification({ order: -1 }));
    getOrders(searchParams)
      .then((res) => {
        setOrders((prev) => ({
          ...prev,
          loading: false,
          ...res,
        }));
      })
      .finally(() => setOrders((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page"), orderNotification]);

  const clickDelete = (id) => {
    deleteOrder(id).then(() => getData());
    setModalDelete({ isShow: false, id: false });
  };

  if (orders.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Сделки" />
      <DataTable
        columns={orderColumns}
        onChange={(items) => setSelected(items)}
        data={orders.items}
        header={header}
        selectable
        pagination={orders.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        isShow={modalDelete.isShow}
        setShow={(e) => setModalDelete({ isShow: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ isShow: !modalDelete.isShow, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => modalDelete.id && clickDelete(modalDelete.id)}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить заказ?
      </CustomModal>
    </>
  );
};

export default Orders;
