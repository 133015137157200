import React, { useCallback, useState } from "react";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate } from "react-router-dom";
import Meta from "../../components/Meta";
import Input from "../../components/UI/Input";
import { createCategory } from "../../services/category";
import DragDropFile from "../../components/DragDropFile";
import Textarea from "../../components/UI/Textarea";
import ImageCropper from "../../components/Cropper";

const CreateCategory = () => {
  const navigate = useNavigate();
  const [editImageProduct, setEditImageProduct] = useState({
    show: false,
    data: [],
  });
  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const onSubmit = useCallback((data) => {
    createCategory(data)
      .then((res) => {
        NotificationManager.success("Игра успешно создана");
        navigate("/catalog/game/" + res.id);
      })
      .catch((error) => {
        NotificationManager.error(
          error?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  return (
    <>
      <Meta title="Создать игру" />
      <div>
        <Link
          to="/catalog/games"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <h3>Создать игру</h3>
        <Button disabled={!isValid} onClick={handleSubmit(onSubmit)}>
          Сохранить
        </Button>
      </div>
      <Card>
        <Card.Body>
          <Row>
            <Col md={8}>
              <div className="mb-3">
                <Input
                  defaultValue={data.title}
                  label="Название"
                  name="title"
                  errors={errors}
                  register={register}
                  validation={{
                    required: "Обязательное поле",
                  }}
                />
              </div>
            </Col>
            <Col md={4}>
              <div className="mb-3">
                <Input
                  defaultValue={0}
                  label="Комиссия"
                  name="commission"
                  min={0}
                  type="number"
                  errors={errors}
                  register={register}
                />
              </div>
            </Col>
            <Col md={12}>
              <Textarea
                defaultValue={data.desc}
                label="Описание"
                name="desc"
                errors={errors}
                rows={6}
                register={register}
              />
            </Col>
          </Row>
        </Card.Body>
      </Card>
      <ImageCropper
        file={editImageProduct.data[0]}
        show={editImageProduct.show}
        setShow={(e) => setEditImageProduct((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("media", e.blob);
        }}
      />
    </>
  );
};

export default CreateCategory;
