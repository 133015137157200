import moment from "moment";
import React from "react";
import { IoCheckmarkDoneOutline, IoCheckmarkOutline } from "react-icons/io5";

const Message = ({ my, time, text, name, view = false }) => {
  time = time
    ? moment(time).format("DD MMMM YYYY kk:mm")
    : moment().format("DD MMMM YYYY kk:mm");

  return my ? (
    <div className="d-flex flex-column align-self-end justify-content-end mb-3">
      <div className="mb-1 align-self-end">
        <time className="fs-08 text-muted">
          {time}
          {view ? (
            <IoCheckmarkDoneOutline className="text-success ms-1" size={15} />
          ) : (
            <IoCheckmarkOutline className="ms-1" size={15} />
          )}
        </time>
      </div>
      <div className="text align-self-end my">
        <p>{text}</p>
      </div>
    </div>
  ) : (
    <div className="d-flex flex-row align-self-start justify-content-start mb-3">
      <div className="pe-3 pt-1">
        <img src="/images/avatar.png" alt={name} />
      </div>
      <div className="d-flex flex-column align-self-start justify-content-start">
        <div className="mb-1 align-self-start">
          <time className="fs-08 text-muted">{time}</time>
        </div>
        <div className="text align-self-start">
          <p>{text}</p>
        </div>
      </div>
    </div>
  );
};

export default Message;
