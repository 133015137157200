import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
  createRef,
} from "react";
import {
  IoCheckmarkCircle,
  IoCloseOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { Link, useSearchParams } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { NotificationManager } from "react-notifications";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { deleteUser, getUsers } from "../../services/user";
// import socket from "../../config/socket";

const Users = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();
  const [users, setUsers] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const userColumns = [
    {
      name: "Ник",
      cell: (row) => (
        <Link to={"/user/" + row.id} className="d-flex align-items-center">
          {row.firstName}
        </Link>
      ),
    },
    {
      name: "Email/Телефон",
      selector: "email",
      cell: (row) => row?.phone ?? row?.email ?? "-",
    },
    {
      name: "Др",
      selector: "birthday",
      align: "center",
    },
    {
      name: "Пол",
      selector: "sex",
      align: "center",
    },
    {
      name: "Telegram",
      selector: "key",
      align: "center",
      cell: (row) => (
        <Link to={"https://t.me/" + row?.telegram?.nickname} className="d-flex align-items-center">
          {row?.telegram?.nickname ?? "-"}
        </Link>
      ),
    },
    {
      name: "Онлайн",
      cell: (row) => (row.online.status ? "Онлайн" : row.online.end),
    },
  ];

  const getData = useCallback(async () => {
    getUsers(searchParams)
      .then(
        (res) =>
          res &&
          setUsers((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setUsers((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `Выбрано ${selected.length}`
                : "Пользователи"}
            </h5>
          </div>
          <div>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Заблокированные", value: "blocked" },
                  { title: "Подтвержденные", value: "verified" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате регистрации: новые", value: "datenew" },
                  { title: "По дате регистрации: старые", value: "dateold" },
                  { title: "Комиссия: больше", value: "commissionmore" },
                  { title: "Комиссия: меньше", value: "commissionless" },
                  { title: "Объявления: больше", value: "adsmore" },
                  { title: "Объявления: меньше", value: "adsless" },
                ]}
                value={searchParams.get("sort") ?? "datenew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={6}>
              <Input
                ref={inputRef}
                placeholder="Найти"
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                defaultValue={searchParams.get("text")}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);
  //   useLayoutEffect(() => {
  //     socket.on("users/" + auth.user.id, (data) => {
  //       if (data?.status) {
  //         let newUsers = users.items.map((e) => {
  //           if (e.id === data.id) {
  //             return data;
  //           }
  //           return e;
  //         });
  //         if (newUsers) {
  //           setUsers((e) => ({ ...e, items: newUsers }));
  //         }
  //       }
  //     });
  //     return () => socket.off("users/" + auth.user.id);
  //   }, [users.items]);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const onDeleteSelected = useCallback(() => {
    deleteUser(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные пользователи успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  if (users.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Пользователи" />
      <DataTable
        columns={userColumns}
        onChange={(items) => setSelected(items)}
        data={users.items}
        header={header}
        selectable
        pagination={users.pagination}
      />
      <CustomModal
        title={`Удаление ${modalDelete.id ? "#" + modalDelete.id : ""}`}
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={(e) =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить пользователя?
      </CustomModal>
    </>
  );
};

export default Users;
