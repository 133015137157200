import { $authApi } from ".";
import { apiRoutes } from "../config/api";

const getBooks = async (data) => {
  const response = await $authApi.get(apiRoutes.BOOKS, {
    params: data,
  });
  return response?.data;
};
const getBook = async (id) => {
  const response = await $authApi.get(apiRoutes.BOOK, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editBook = async (data) => {
  const response = await $authApi.put(apiRoutes.BOOKS, data);
  return response?.data;
};
const deleteBook = async (id) => {
  const response = await $authApi.delete(apiRoutes.BOOKS, {
    data: { id },
  });
  return response?.data;
};
const createBook = async (data) => {
  const response = await $authApi.post(apiRoutes.BOOKS, data);
  return response?.data;
};
export {
  getBooks,
  getBook,
  editBook,
  deleteBook,
  createBook,
};
