import "bootstrap/dist/css/bootstrap.min.css";
import React, { useLayoutEffect, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "swiper/css";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
import "swiper/css/free-mode";
import "swiper/css/mousewheel";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "./assets/styles/style.min.css";
import Loader from "./components/UI/Loader";
import socket from "./config/socket";
import AppRouter from "./routes/AppRouter";
import { checkAuth, logout } from "./services/auth";
import { setAuth, setUser } from "./store/reducers/authSlice";
import axios from "axios";
import { setSettings } from "./store/reducers/settingsSlice";
import { updateNotification } from "./store/reducers/notificationSlice";

const App = () => {
  const isAuth = useSelector((state) => state.auth.isAuth);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);

  useLayoutEffect(() => {
    (async () =>
      await axios
        .get("https://ip.yooapp.ru")
        .then(
          ({ data }) => data?.ip && dispatch(setSettings({ ip: data.ip }))
        ))();

    if (localStorage.getItem("token")) {
      checkAuth()
        .then((data) => {
         
          data.member && dispatch(setUser(data.member));
          data.member && dispatch(setAuth(true));
          socket.io.opts.query = {
            memberId: data.member.id,
          };
          socket.connect();
          socket.emit("member/add", {
            memberId: data.member.id,
          });
        })
        .catch((err) => console.log(err))
        .finally(() => setLoading(false));
    } else {
      setLoading(false);
    }
    return () => {
      socket.off("connect");
    };
  }, []);

  useEffect(() => {
    if (isAuth) {
      socket.on("notifications", (data) => {
        if (data) {
          dispatch(updateNotification(data));
        }
      });

      return () => {
        socket.off("notifications");
      };
    }
  }, [isAuth]);

  if (loading) {
    return <Loader full />;
  }

  return <AppRouter />;
};
export default App;
