import moment from "moment";
import React, { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { Badge, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoChevronBackOutline,
  IoEllipsisVertical,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useParams } from "react-router-dom";
import Chat from "../../components/chat";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import CustomModal from "../../components/utils/CustomModal";
import { getImageURL } from "../../helpers/image";
import { editTask, getTask } from "../../services/task";

const EditTask = () => {
  const { taskId } = useParams();
  const [loading, setLoading] = useState(true);

  const {
    control,
    register,
    formState: { errors, isValid },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });
  const form = useWatch({ control });
  console.log(form);

  const getData = () => {
    getTask({ id: taskId })
      .then((res) => reset(res))
      .finally(() => setLoading(false));
  };

  useLayoutEffect(() => {
    getData();
  }, [taskId]);

  const onSubmit = useCallback((data) => {
    editTask(data)
      .then(() => NotificationManager.success("Данные успешно обновлены"))
      .catch((err) =>
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        )
      );
  }, []);

  if (loading) {
    return <Loader full />;
  }

  if (!form?.id) {
    return (
      <>
        <Meta title="Редактировать задачи" />
        <Info>
          <svg
            className="mb-3"
            width="60"
            height="60"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              opacity="0.32"
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
              fill="#999"
            />
            <path
              d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
              fill="#999"
            />
          </svg>
          <h3>Такой задачи не существует</h3>
        </Info>
      </>
    );
  }

  return (
    <>
      <Meta title="Редактировать задачи" />
      <div>
        <Link
          to="/tasks"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к задачам
        </Link>
      </div>

      <Card className="mb-3" body>
        <h3 className="mb-4">Задача #{form?.id}</h3>
        <Row>
          <Col md={6}>
            <div className="d-flex align-items-center mb-3">
              <div className="w-100 d-flex justify-content-between">
                <div>
                  <p className="fw-6">Тип</p>
                </div>
                <div className="d-flex flex-column justify-content-between align-items-center">
                  {form.type === "task" ? (
                    <Badge bg="success">Обращение</Badge>
                  ) : form.type === "word" ? (
                    <Badge bg="warning">Стоп-слово</Badge>
                  ) : (
                    form.type === "report" && <Badge bg="danger">Жалоба</Badge>
                  )}
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="w-100 d-flex justify-content-between">
                <div>
                  <p className="fw-6">Дата и время</p>
                </div>
                <div className="d-flex flex-column justify-content-between fs-09 align-items-center">
                  <p>
                    <span className="me-1">
                      {moment(form.createdAt).format("DD.MM.YYYY")}
                    </span>
                    <span className="fw-7">
                      {moment(form.createdAt).format("kk:mm")}
                    </span>
                  </p>
                </div>
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              <div className="w-100 d-flex justify-content-between">
                <div>
                  <p className="fw-6">Статус</p>
                </div>
                <div className="d-flex flex-column justify-content-between fs-09 align-items-center">
                  <Select
                    data={[
                      { title: "Новый", value: "new" },
                      { title: "В процессе", value: "process" },
                      { title: "Выполнено", value: "ok" },
                      { title: "Закрыто", value: "close" },
                    ]}
                    value={form?.status ?? "new"}
                    onClick={(e) => setValue("status", e.value)}
                  />
                </div>
              </div>
            </div>
            <div className="mb-3">
              <Textarea
                label="Комментарий"
                name="admincomment"
                rows={4}
                errors={errors}
                register={register}
                validation={{
                  maxLength: {
                    value: 1500,
                    message: "Максимально 500 символов",
                  },
                }}
              />
            </div>

            <Button
              className="btn btn-primary w-100"
              disabled={!isValid}
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить
            </Button>
          </Col>
          <Col>
            <h5>Описание</h5>
            <div>{form?.comment}</div>
          </Col>
        </Row>
      </Card>

      {form?.type == "task" && (
        <Card className="mb-3 dialog">
          <Card.Header className="border-bottom">
            <div className="d-flex align-items-center justify-content-between">
              <div>
                <h5 className="fw-6">
                  Чат c {form.nickname}
                  <span className="text-muted fw-5">{" • "}Обращение №22</span>
                </h5>
              </div>
              <div>
                <IoEllipsisVertical size={22} />
              </div>
            </div>
          </Card.Header>
          <Row>
            <Col>
              <div className="p-4">
                <Chat
                  data={[
                    {
                      my: true,
                      text: "Слушаем вас",
                      view: true,
                    },
                    {
                      my: false,
                      text: "Тест сообщение",
                      name: form.nickname,
                      view: false,
                    },
                    {
                      my: false,
                      text: "Есть вопрос",
                      name: form.nickname,
                      view: false,
                    },
                  ]}
                  autoFocus={false}
                  emptyText="Нет сообщений"
                  onSubmit={(e) => e && onSendNote(e)}
                />
              </div>
            </Col>
            <Col md={4} className="list position-relative border-left">
              {/* <div className="p-4 position-sticky top-1">
                  <a className="d-block mb-3">
                    <p className="d-flex align-items-center justify-content-between">
                      <span className="fw-6">Обращение №22</span>
                      <span className="text-muted fs-08">10.10.2023 10:20</span>
                    </p>
                    <p className="text-muted fs-09 text-transfer">
                      Есть вопросы, возможно ли...
                    </p>
                  </a>
                  <a className="d-block mb-3">
                    <p className="d-flex align-items-center justify-content-between">
                      <span className="fw-6">Обращение №22</span>
                      <span className="text-muted fs-08">10.10.2023 10:20</span>
                    </p>
                    <p className="text-muted fs-09 text-transfer">
                      Есть вопросы, возможно ли...
                    </p>
                  </a>
                  <a className="d-block mb-3">
                    <p className="d-flex align-items-center justify-content-between">
                      <span className="fw-6">Обращение №22</span>
                      <span className="text-muted fs-08">10.10.2023 10:20</span>
                    </p>
                    <p className="text-muted fs-09 text-transfer">
                      Есть вопросы, возможно ли...
                    </p>
                  </a>
                </div> */}
            </Col>
          </Row>
        </Card>
      )}
    </>
  );
};

export default EditTask;
