import React, { useLayoutEffect, useEffect, useState } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import Home from "./Home";
import Meta from "../components/Meta";
import { Link } from "react-router-dom";
import { getStatistics } from "../services/statistic";
import socket from "../config/socket";

const Admin = () => {
  const auth = useSelector((state) => state?.auth);

  if (!auth.isAuth) {
    return <Home />;
  }

  const [statistics, setStatistics] = useState({
    users: 0,
    online: 0,
    total: 0,
  });

  useLayoutEffect(() => {
    getStatistics().then((res) => setStatistics(res));
  }, []);

  useEffect(() => {
    socket.emit("create", "admin");
    socket.on("statistic", (data) => {
      if (data) {
        setStatistics(data)
      }
    });

    return () => {
      socket.off("statistic");
    };
  }, []);

  return (
    <>
      <Meta title="Главная" />
      <Row className="mb-3">
        <Col md={4}>
          <Link className="analytics flex-column align-items-center justify-content-center deals mb-3">
            <img
              src="/images/analitics/analitics-deals.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">{statistics?.users}</h4>
            <span>Пользователей</span>
          </Link>
        </Col>
        <Col md={4}>
          <Link className="analytics flex-column align-items-center justify-content-center ads mb-3">
            <img
              src="/images/analitics/analitics-ads.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">{statistics?.online}</h4>
            <span>Онлайн</span>
          </Link>
        </Col>
        <Col md={4}>
          <Link className="analytics flex-column align-items-center justify-content-center tikets mb-3">
            <img
              src="/images/analitics/analitics-tickets.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">{statistics?.total}</h4>
            <span>Баланс</span>
          </Link>
        </Col>
        {/* <Col md={3}>
          <Link className="analytics flex-column align-items-center justify-content-center pay mb-3">
            <img
              src="/images/analitics/analitics-pay.svg"
              height={65}
              className="mb-4"
            />
            <h4 className="mb-1">369 541</h4>
            <span>Комиссия за месяц</span>
          </Link>
        </Col> */}
      </Row>
      <Row>
        <Col md={4}>
          <Card body>
            <h5 className="mb-3 fw-6 h6">Статистика по задачам</h5>
            <div className="d-flex text-muted fs-09">Пока ничего нет</div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <h5 className="mb-3 fw-6 h6">Топ админов</h5>
            <div className="d-flex text-muted fs-09">Пока ничего нет</div>
          </Card>
        </Col>
        <Col md={4}>
          <Card body>
            <h5 className="mb-3 fw-6 h6">Топ пользователей</h5>
            <div className="d-flex text-muted fs-09">Пока ничего нет</div>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Admin;
