import { useSelector } from "react-redux";
import Message from "./Message";
import { IoSend } from "react-icons/io5";
import { memo, useCallback, useState } from "react";
import Input from "../UI/Input";

const Chat = memo(
  ({
    data,
    className,
    emptyText = "Нет сообщений",
    onSubmit,
    onChange,
    autoFocus = true,
  }) => {
    const auth = useSelector((state) => state.auth);
    const [text, setText] = useState("");

    const onChangeText = (e) => {
      setText(e);
      onChange(e);
    };

    const onClick = useCallback(() => {
      if (text.length > 0) {
        onSubmit(text);
        setText("");
      }
    }, [text]);

    return (
      <>
        <div className={"chat " + className}>
          {data.length > 0 ? (
            data.map((e) => (
              <Message
                my={e.memberId === auth.user.id}
                name="Альберт"
                time={e.createdAt}
                text={e.text}
                view={e.view}
              />
            ))
          ) : (
            <div className="w-100 py-5 text-center text-muted fs-09 d-flex flex-column align-items-center justify-content-center">
              {emptyText}
            </div>
          )}
        </div>
        <div className="d-flex align-items-center position-relative pt-2">
          <div className="me-3">
            <img src="/images/avatar.png" />
          </div>
          <div className="input w-100">
            <Input
              autofocus={autoFocus}
              className="pe-5"
              value={text}
              placeholder="Введите сообщение"
              onChange={(e) => onChangeText(e)}
            />
          </div>
          <a onClick={() => onClick()} className="position-absolute end-0 me-2">
            <IoSend
              size={22}
              className={text.length > 0 ? "text-success" : "text-muted"}
            />
          </a>
        </div>
      </>
    );
  }
);

export default Chat;
