import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Accordion, Card, Col, Form, Row, Tab, Tabs } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import {
  IoChevronBackOutline,
  IoCreateOutline,
  IoImageOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import TagsInput from "react-tagsinput";
import ImageCropper from "../../components/Cropper";
import DataTable from "../../components/DataTable";
import DragDropFile from "../../components/DragDropFile";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Info from "../../components/UI/Info";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import Textarea from "../../components/UI/Textarea";
import CustomModal from "../../components/utils/CustomModal";
import { convertWeight, customPrice, kkal } from "../../helpers/product";
import { getAdditions } from "../../services/addition";
import { getCategories } from "../../services/category";
import { getIngredients } from "../../services/ingredient";
import { getModifiers } from "../../services/modifier";
import { editProduct, getProduct } from "../../services/product";

const ProductEdit = () => {
  const { productId } = useParams();
  const brand = useSelector((state) => state?.brand?.active);
  const sync = !!brand?.options?.type;

  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });

  const [editImageProduct, setEditImageProduct] = useState({
    show: false,
    data: [],
  });
  const [createModifier, setCreateModifier] = useState({
    show: false,
    data: {},
  });
  const [editModifier, setEditModifier] = useState({
    show: false,
    data: {},
  });
  const [listModifier, setListModifier] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalModifierDelete, setModalModifierDelete] = useState({
    show: false,
    id: false,
  });
  const [listAddition, setListAddition] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalAdditionDelete, setModalAdditionDelete] = useState({
    show: false,
    id: false,
  });
  const [editIngredient, setEditIngredient] = useState({
    show: false,
    data: {},
  });
  const [listStorage, setListIngredient] = useState({
    loading: true,
    show: false,
    items: [],
    selected: [],
  });
  const [modalIngredientDelete, setModalIngredientDelete] = useState({
    show: false,
    id: false,
  });

  const [loading, setLoading] = useState(true);

  const modifierColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Категория",
      selector: "category.title",
      cell: (row) => row.category.title,
    },
    {
      width: "80px",
      name: "Цена",
      selector: "price",
      cell: (row) => customPrice(row.price),
    },
  ];
  const additionColumns = [
    {
      name: "Название",
      selector: "title",
    },
  ];
  const ingredientColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Описание",
      selector: "desc",
    },
    {
      name: "БЖУ",
      cell: (row) => (
        <span>
          {row.energy.protein}
          <sup>Б</sup> {row.energy.fat}
          <sup>Ж</sup> {row.energy.carbohydrate}
          <sup>У</sup>
        </span>
      ),
    },
  ];

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
  });

  const data = useWatch({ control });

  const kkalData = useRef();

  useEffect(() => {
    if (listModifier.show) {
      if (!listModifier?.items?.length) {
        setListModifier((e) => ({
          ...e,
          loading: true,
        }));
      }
      getModifiers({ size: 250 })
        .then((res) =>
          setListModifier((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListModifier((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listModifier.show]);

  useEffect(() => {
    if (listAddition.show) {
      if (!listAddition?.items?.length) {
        setListAddition((e) => ({
          ...e,
          loading: true,
        }));
      }
      getAdditions({ size: 250, modal: true })
        .then((res) =>
          setListAddition((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListAddition((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listAddition.show]);

  useEffect(() => {
    if (listStorage.show) {
      if (!listStorage?.items?.length) {
        setListIngredient((e) => ({
          ...e,
          loading: true,
        }));
      }
      getIngredients({ size: 250 })
        .then((res) =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
            ...res,
          }))
        )
        .catch(() =>
          setListIngredient((e) => ({
            ...e,
            loading: false,
          }))
        );
    }
  }, [listStorage.show]);

  useLayoutEffect(() => {
    getCategories({ size: 250 })
      .then((res) => {
        setCategories((prev) => ({ ...prev, loading: false, ...res }));
      })
      .catch(() => setCategories((prev) => ({ ...prev, loading: false })));
    getProduct(productId)
      .then((res) => {
        if (res) {
          reset(res);
          kkalData.current = kkal(res?.product?.storages);
          setLoading(false);
        }
      })
      .catch(() => setLoading(false));
  }, []);

  const onSubmit = useCallback((data) => {
    editProduct(data)
      .then(() => NotificationManager.success("Товар успешно обновлен"))
      .catch((err) => {
        NotificationManager.error(
          err?.response?.data?.error ?? "Ошибка при сохранении"
        );
      });
  }, []);

  const FormModifier = ({ data, onChange }) => {
    return (
      <Row>
        <Col md={3}>
          <div className="mb-3 upload-box modifier d-flex flex-column align-items-center justify-content-center">
            <IoImageOutline size={25} className="text-muted" />
          </div>
        </Col>
        <Col md={9}>
          <Row>
            <Col md={12}>
              <div className="mb-3">
                <Input
                  defaultValue={data.title ?? ""}
                  label="Название"
                  name="title"
                  onChange={(e) => onChange && onChange("title", e)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  defaultValue={data.weight ?? ""}
                  label="Вес, г"
                  name="weight"
                  type="number"
                  onChange={(e) => onChange && onChange("weight", e)}
                />
              </div>
            </Col>
            <Col md={6}>
              <div className="mb-3">
                <Input
                  defaultValue={data.price ?? ""}
                  label="Цена"
                  type="number"
                  readOnly={false}
                  name="price"
                />
              </div>
            </Col>
          </Row>
        </Col>
        <Col md={12}>
          <div className="mb-3">
            <Textarea
              defaultValue={data.description ?? ""}
              label="Описание"
              name="description"
              rows={4}
              onChange={(e) => onChange && onChange("description", e)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  const FormIngredient = ({ data, onChange }) => {
    return (
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.weight ?? 0}
              label="Вес, г"
              name="weight"
              type="number"
              onChange={(e) => onChange && onChange("weight", e)}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={data.price ?? 0}
              label="Цена"
              type="number"
              name="price"
              onChange={(e) => onChange && onChange("price", e)}
            />
          </div>
        </Col>
      </Row>
    );
  };

  if (loading || categories.loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого товара нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={data.title ?? "Товар"} />
      <div>
        <Link
          to="/products"
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
      </div>
      <h3 className="mb-4">Редактирование товара</h3>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Row>
                <Col md={8}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.street}
                      label="Название"
                      name="title"
                      errors={errors}
                      register={register}
                      validation={{
                        required: "Обязательное поле",
                      }}
                    />
                  </div>
                </Col>
                <Col md={4}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.home}
                      label="Артикул"
                      name="code"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={12}>
                  <div className="mb-3">
                    <Textarea
                      defaultValue={data.description}
                      label="Описание"
                      name="description"
                      errors={errors}
                      rows={6}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Категория"
                      search
                      value={data.categoryId}
                      data={categories.items.map((e) => ({
                        title: e.title,
                        value: e.id,
                      }))}
                      onClick={(e) => setValue("categoryId", e.value)}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mb-3">
                    <Select
                      label="Рецепты"
                      data={[{ title: "Без рецепта", value: false }]}
                    />
                  </div>
                </Col>
                {/* <Col md={12}>
                  <div className="mt-3">
                    <TagsInput
                      value={data.tags ?? []}
                      placeholder="Введите тег"
                      onChange={(item) => setValue("tags", item)}
                    />
                  </div>
                </Col> */}
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.sticks}
                      label="Кол-во приборов"
                      name="sticks"
                      type="number"
                      max={100}
                      min={0}
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col md={6}>
                  <div className="mb-3">
                    <Input
                      defaultValue={data.priority}
                      label="Порядок"
                      name="priority"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                {data.apiId && (
                  <Col md={12}>
                    <Input
                      defaultValue={data.apiId}
                      label="API ID"
                      name="apiId"
                      readOnly={false}
                    />
                  </Col>
                )}
              </Row>
            </Card.Body>
            <Tabs defaultActiveKey={1} className="mb-3" fill>
              <Tab eventKey={1} title="Состав" className="px-4 pb-4">
                {data?.storages?.length > 0 ? (
                  <>
                    <div className="d-flex">
                      <Button
                        disabled={sync}
                        className="btn-primary me-3"
                        onClick={() =>
                          !sync &&
                          setListIngredient((e) => ({ ...e, show: true }))
                        }
                      >
                        Создать
                      </Button>
                      <Button
                        className="btn-primary-outline"
                        onClick={() =>
                          setListIngredient((e) => ({ ...e, show: true }))
                        }
                      >
                        Выбрать из списка
                      </Button>
                    </div>
                    <Accordion defaultActiveKey="0" className="mt-3">
                      {data.storages.map((e, index) => (
                        <Accordion.Item eventKey={index}>
                          <div className="d-flex align-items-center">
                            <Accordion.Header className="w-100">
                              {e?.title ??
                                e?.ingredient?.title ??
                                "Нет названия продукта"}
                            </Accordion.Header>
                            <a
                              className="mx-2"
                              onClick={() =>
                                setEditIngredient((info) => ({
                                  show: !info.show,
                                  data: e,
                                }))
                              }
                            >
                              <IoCreateOutline
                                size={22}
                                className="text-muted"
                              />
                            </a>
                            <a
                              className="mx-2"
                              onClick={() =>
                                setModalIngredientDelete({
                                  show: !modalIngredientDelete.show,
                                  id: e.id,
                                })
                              }
                            >
                              <IoTrashOutline
                                size={20}
                                className="text-danger"
                              />
                            </a>
                          </div>
                          <Accordion.Body>
                            <Row>
                              <Col md="auto">
                                <img
                                  src="/images/empty-product-image.png"
                                  width={40}
                                  height={40}
                                />
                              </Col>
                              <Col>
                                <p className="text-muted fs-09">Описание</p>
                                {e?.desc ??
                                  e?.ingredient?.desc ??
                                  "Описания нет"}
                              </Col>
                              <Col
                                md="auto"
                                className="d-flex flex-column align-items-end"
                              >
                                <p className="text-muted fs-09">Цена</p>
                                {customPrice(e.price)}
                              </Col>
                              <Col
                                md="auto"
                                className="d-flex flex-column align-items-end"
                              >
                                <p className="text-muted fs-09">Вес</p>
                                {convertWeight(e.weight ?? 0)}
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </>
                ) : (
                  <p className="my-5 text-muted text-center">
                    Состав отсутствует
                    <div className="mt-3 d-flex justify-content-center">
                      <Link
                        disabled={sync}
                        className="btn-primary me-3"
                        to="/ingredient/create"
                      >
                        Создать
                      </Link>
                      <Button
                        className="btn-primary-outline"
                        onClick={() =>
                          setListIngredient((e) => ({ ...e, show: true }))
                        }
                      >
                        Выбрать из списка
                      </Button>
                    </div>
                  </p>
                )}
              </Tab>
              <Tab eventKey={2} title="Добавки" className="px-4 pb-4">
                {data?.additions?.length > 0 ? (
                  <>
                    <div className="d-flex">
                      <Button
                        disabled={sync}
                        className="btn-primary me-3"
                        onClick={() =>
                          !sync &&
                          setCreateAddition((e) => ({ ...e, show: true }))
                        }
                      >
                        Создать
                      </Button>
                      <Button
                        className="btn-primary-outline"
                        onClick={() =>
                          setListAddition((e) => ({ ...e, show: true }))
                        }
                      >
                        Выбрать из списка
                      </Button>
                    </div>
                    <Accordion defaultActiveKey="0" className="mt-3">
                      {data.additions.map((e, index) => (
                        <Accordion.Item eventKey={index}>
                          <div className="d-flex align-items-center">
                            <Accordion.Header className="w-100">
                              {e?.title ?? e?.addition?.title ?? ""}
                            </Accordion.Header>
                            <a
                              className="mx-2"
                              // onClick={() =>
                              //   setEditAddition((info) => ({
                              //     show: !info.show,
                              //     data: e,
                              //   }))
                              // }
                            >
                              <IoCreateOutline
                                size={22}
                                className="text-muted"
                              />
                            </a>
                            <a
                              className="mx-2"
                              onClick={() =>
                                setModalAdditionDelete({
                                  show: !modalAdditionDelete.show,
                                  id: e.id,
                                })
                              }
                            >
                              <IoTrashOutline
                                size={20}
                                className="text-danger"
                              />
                            </a>
                          </div>
                          <Accordion.Body>
                            <Row className="mb-2">
                              <Col md="auto">
                                <img
                                  src="/images/empty-product-image.png"
                                  width={40}
                                  height={40}
                                />
                              </Col>
                              <Col>
                                <p>{e?.title ?? "Нет названия"}</p>
                                <p className="text-muted fs-09">
                                  {e?.description ?? "Описания нет"}
                                </p>
                              </Col>
                              <Col
                                md="auto"
                                className="d-flex flex-column align-items-end"
                              >
                                <p className="text-muted fs-09">Цена</p>
                                {customPrice(e?.price)}
                              </Col>
                              <Col
                                md="auto"
                                className="d-flex flex-column align-items-end"
                              >
                                <p className="text-muted fs-09">Вес</p>
                                {convertWeight(e?.energy?.weight ?? 0)}
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </>
                ) : (
                  <p className="my-5 text-muted text-center">
                    Добавок нет
                    <div className="mt-3 d-flex justify-content-center">
                      <Button
                        disabled={sync}
                        className="btn-primary me-3"
                        onClick={() =>
                          !sync &&
                          setCreateModifier((e) => ({ ...e, show: true }))
                        }
                      >
                        Добавить добавку
                      </Button>
                      <Button
                        className="btn-primary-outline"
                        onClick={() =>
                          setListAddition((e) => ({ ...e, show: true }))
                        }
                      >
                        Выбрать из списка
                      </Button>
                    </div>
                  </p>
                )}
              </Tab>
              <Tab eventKey={3} title="Модификаторы" className="px-4 pb-4">
                {data?.modifiers?.length > 0 ? (
                  <>
                    <div className="d-flex">
                      <Button
                        disabled={sync}
                        className="btn-primary me-3"
                        onClick={() =>
                          !sync &&
                          setCreateModifier((e) => ({ ...e, show: true }))
                        }
                      >
                        Создать
                      </Button>
                      <Button
                        className="btn-primary-outline"
                        onClick={() =>
                          setListModifier((e) => ({ ...e, show: true }))
                        }
                      >
                        Выбрать из списка
                      </Button>
                    </div>
                    <Accordion defaultActiveKey="0" className="mt-3">
                      {data.modifiers.map((e, index) => (
                        <Accordion.Item eventKey={index}>
                          <div className="d-flex align-items-center">
                            <Form.Check.Input
                              type="radio"
                              name="main"
                              checked={e.main}
                              defaultChecked={e.main}
                              onChange={(value) =>
                                setValue(
                                  "modifiers",
                                  data.modifiers.map((mod) => {
                                    mod.main = false;
                                    if (mod.id === e.id) {
                                      mod = e;
                                      mod.main = value.target.checked;
                                    }
                                    return mod;
                                  })
                                )
                              }
                            />
                            <Accordion.Header className="w-100  flex-1 d-flex align-items-center">
                              {e.title}
                            </Accordion.Header>
                            <a
                              className="mx-2"
                              onClick={() =>
                                setEditModifier((info) => ({
                                  show: !info.show,
                                  data: e,
                                }))
                              }
                            >
                              <IoCreateOutline
                                size={22}
                                className="text-muted"
                              />
                            </a>
                            <a
                              className="mx-2"
                              onClick={() =>
                                setModalModifierDelete({
                                  show: !modalModifierDelete.show,
                                  id: e.id,
                                })
                              }
                            >
                              <IoTrashOutline
                                size={20}
                                className="text-danger"
                              />
                            </a>
                          </div>
                          <Accordion.Body>
                            <Row>
                              <Col md="auto">
                                <img
                                  src="/images/empty-product-image.png"
                                  width={40}
                                  height={40}
                                />
                              </Col>
                              <Col>
                                <p className="text-muted fs-09">Описание</p>
                                {e?.description ?? "Описания нет"}
                              </Col>
                              <Col
                                md="auto"
                                className="d-flex flex-column align-items-end"
                              >
                                <p className="text-muted fs-09">Цена</p>
                                {customPrice(e.price)}
                              </Col>
                              <Col
                                md="auto"
                                className="d-flex flex-column align-items-end"
                              >
                                <p className="text-muted fs-09">Вес</p>
                                {convertWeight(e.weight ?? 0)}
                              </Col>
                            </Row>
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                    </Accordion>
                  </>
                ) : (
                  <p className="my-5 text-muted text-center">
                    Модификаторов нет
                    <div className="mt-3 d-flex justify-content-center">
                      <Button
                        disabled={sync}
                        className="btn-primary me-3"
                        onClick={() =>
                          !sync &&
                          setCreateModifier((e) => ({ ...e, show: true }))
                        }
                      >
                        Добавить модификатор
                      </Button>
                      <Button
                        className="btn-primary-outline"
                        onClick={() =>
                          setListModifier((e) => ({ ...e, show: true }))
                        }
                      >
                        Выбрать из списка
                      </Button>
                    </div>
                  </p>
                )}
              </Tab>
            </Tabs>
          </Card>
        </Col>
        <Col md={4} className="position-relative">
          <div className="position-sticky top-1">
            <Card className="mb-3" body>
              <DragDropFile
                file={data.medias}
                onChange={(e) =>
                  setEditImageProduct((info) => ({
                    show: !info.show,
                    data: e,
                  }))
                }
              />
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">
                Энергетическая ценность в 100г -{" "}
                <span className="text-success">
                  {kkalData?.current?.kkal ?? 0}
                </span>{" "}
                ккал
              </p>
              <Row className="gx-2">
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.protein ?? 0}
                      label="Белки, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.fat ?? 0}
                      label="Жиры, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.carbohydrate ?? 0}
                      label="Углеводы, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
              </Row>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      value={kkalData?.current?.kkalAll ?? 0}
                      label="Всего ккал, г"
                      readOnly={false}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={
                        data.energy.weight ?? kkalData?.current?.weight ?? 0
                      }
                      label="Вес, г"
                      name="energy.weight"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Card body className="mb-3">
              <p className="fs-08">Цена товара</p>
              <Row className="gx-2">
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={data.price}
                      label="Цена"
                      name="price"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="mt-4">
                    <Input
                      defaultValue={data.discount}
                      label="Скидка"
                      name="discount"
                      min={0}
                      type="number"
                      errors={errors}
                      register={register}
                    />
                  </div>
                </Col>
              </Row>
            </Card>
            <Button
              className="btn-primary align-self-end w-100 mt-3"
              onClick={handleSubmit(onSubmit)}
            >
              Сохранить товар
            </Button>
          </div>
        </Col>
      </Row>
      <CustomModal
        size="lg"
        title="Редактировать модификатор"
        show={editModifier.show}
        setShow={(e) => setEditModifier((list) => ({ ...list, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setEditModifier((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                setValue(
                  "modifiers",
                  data.modifiers.map((e) =>
                    e.id === editModifier.data.id ? editModifier.data : e
                  )
                );
                setEditModifier({ show: false, data: {} });
              }}
            >
              Сохранить модификатор
            </Button>
          </>
        }
      >
        <FormModifier
          data={editModifier.data}
          onChange={(title, value) =>
            setEditModifier((info) => {
              info.data[title] = value;
              return info;
            })
          }
        />
      </CustomModal>
      <CustomModal
        size="lg"
        title="Создать модификатор"
        show={createModifier.show}
        setShow={(e) => setCreateModifier((list) => ({ ...list, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setCreateModifier((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                !sync &&
                  setValue("modifiers", [
                    ...data.modifiers,
                    createModifier.data,
                  ]);
                !sync && setCreateModifier({ show: false, data: {} });
              }}
            >
              Сохранить модификатор
            </Button>
          </>
        }
      >
        <FormModifier
          data={createModifier.data}
          onChange={(title, value) =>
            !sync &&
            setCreateModifier((info) => {
              info.data[title] = value;
              return info;
            })
          }
        />
      </CustomModal>
      <CustomModal
        title="Список модификаторов"
        show={listModifier.show}
        setShow={(e) => setListModifier((list) => ({ ...list, show: e }))}
        classNameBody="py-0 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListModifier((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listModifier?.selected.length === 0}
              onClick={() => {
                if (listModifier?.selected.length > 0) {
                  setValue(
                    "modifiers",
                    data.modifiers.concat(
                      listModifier.selected.map((e) => e.item)
                    )
                  );
                  setListModifier((e) => ({
                    ...e,
                    items: listModifier.items.filter(
                      (e) =>
                        !listModifier.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listModifier.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listModifier?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет модификаторов</p>
        ) : (
          <DataTable
            columns={modifierColumns}
            data={listModifier.items}
            onChange={(items) =>
              setListModifier((e) => ({ ...e, selected: items }))
            }
            lite
            headClassName="light"
            selectable
          />
        )}
      </CustomModal>

      <ImageCropper
        file={editImageProduct.data[0]}
        show={editImageProduct.show}
        setShow={(e) => setEditImageProduct((info) => ({ ...info, show: e }))}
        onComplete={(e) => {
          e && setValue("file", e.file);
          e && setValue("medias", e.blob);
        }}
      />

      <CustomModal
        title="Удаление элемента"
        show={modalModifierDelete.show}
        setShow={(e) => setModalModifierDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalModifierDelete({
                  show: !modalModifierDelete.show,
                  id: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "modifiers",
                  data.modifiers.filter((e) => e.id != modalModifierDelete.id)
                );
                setListModifier((e) => ({
                  ...e,
                  items: [
                    ...listModifier.items,
                    data.modifiers.find((e) => e.id === modalModifierDelete.id),
                  ],
                  selected: [],
                }));
                setModalModifierDelete({ show: false, id: false });
              }}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить модификатор из товара?
      </CustomModal>
      <CustomModal
        size="lg"
        title="Редактировать состав"
        show={editIngredient.show}
        setShow={(e) => setEditIngredient((list) => ({ ...list, show: e }))}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setEditIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              onClick={() => {
                let newArray = data.storages.map((e) =>
                  e.id === editIngredient.data.id ? editIngredient.data : e
                );
                setValue("storages", newArray);
                setEditIngredient({ show: false, data: {} });
                kkalData.current = kkal(newArray);
              }}
            >
              Сохранить продукт
            </Button>
          </>
        }
      >
        <FormIngredient
          data={editIngredient.data}
          onChange={(title, value) =>
            setEditIngredient((info) => {
              info.data[title] = value;
              return info;
            })
          }
        />
      </CustomModal>
      <CustomModal
        title="Список продукции"
        show={listStorage.show}
        setShow={(e) => setListIngredient((list) => ({ ...list, show: e }))}
        classNameBody="py-2 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListIngredient((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listStorage?.selected?.length === 0}
              onClick={() => {
                if (listStorage?.selected?.length > 0) {
                  let newArray = data.storages.concat(
                    listStorage.selected.map((e) => ({
                      productId: data.id,
                      ingredientId: e.item.id,
                      ingredient: e.item,
                      weight: 0,
                      price: 0,
                    }))
                  );
                  setValue("storages", newArray);
                  setListIngredient((e) => ({
                    ...e,
                    items: listStorage.items.filter(
                      (e) =>
                        !listStorage.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                  kkalData.current = kkal(newArray);
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listStorage.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listStorage?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет продукции</p>
        ) : (
          <DataTable
            columns={ingredientColumns}
            data={listStorage.items}
            onChange={(items) =>
              setListIngredient((e) => ({ ...e, selected: items }))
            }
            lite
            selectable
          />
        )}
      </CustomModal>
      <CustomModal
        title="Удаление элемента"
        show={modalIngredientDelete.show}
        setShow={(e) => setModalIngredientDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() =>
                setModalIngredientDelete({
                  show: !modalIngredientDelete.show,
                  id: false,
                })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() => {
                setValue(
                  "storages",
                  data.storages.filter((e) => e.id != modalIngredientDelete.id)
                );
                setListIngredient((e) => ({
                  ...e,
                  items: [
                    ...listStorage.items,
                    data.storages.find(
                      (e) => e.id === modalIngredientDelete.id
                    ),
                  ],
                  selected: [],
                }));
                setModalIngredientDelete({ show: false, id: false });
              }}
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить состав из товара?
      </CustomModal>
      <CustomModal
        title="Список добавок"
        show={listAddition.show}
        setShow={(e) => setListAddition((list) => ({ ...list, show: e }))}
        classNameBody="py-0 px-0"
        footer={
          <>
            <Button
              className=" me-3"
              onClick={() => setListAddition((e) => ({ ...e, show: false }))}
            >
              Отмена
            </Button>
            <Button
              className="btn-primary"
              disabled={listAddition?.selected.length === 0}
              onClick={() => {
                if (listAddition?.selected.length > 0) {
                  setValue(
                    "additions",
                    data.additions.concat(
                      listAddition.selected.map((e) => e.item.additions)
                    )[0]
                  );
                  setListAddition((e) => ({
                    ...e,
                    items: listAddition.items.filter(
                      (e) =>
                        !listAddition.selected.find((e2) => e2.item.id === e.id)
                    ),
                    selected: [],
                    show: false,
                  }));
                }
              }}
            >
              Добавить выбранное
            </Button>
          </>
        }
      >
        {listAddition.loading ? (
          <Loader className="d-flex m-auto my-5" />
        ) : listAddition?.items.length === 0 ? (
          <p className="my-5 text-muted text-center">Пока нет добавок</p>
        ) : (
          <DataTable
            columns={additionColumns}
            data={listAddition.items}
            onChange={(items) =>
              setListAddition((e) => ({ ...e, selected: items }))
            }
            lite
            headClassName="light"
            selectable
          />
        )}
      </CustomModal>
    </>
  );
};

export default ProductEdit;
