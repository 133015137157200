import { $authApi } from ".";
import { apiRoutes } from "../config/api";
function createFormData(object, form, namespace) {
  const formData = form || new FormData();
  for (let property in object) {
    if (!object.hasOwnProperty(property) || !object[property]) {
      continue;
    }
    const formKey = namespace ? `${namespace}[${property}]` : property;
    if (object[property] instanceof Date) {
      formData.append(formKey, object[property].toISOString());
    } else if (
      typeof object[property] === "object" &&
      !(object[property] instanceof File)
    ) {
      createFormData(object[property], formData, formKey);
    } else {
      formData.append(formKey, object[property]);
    }
  }
  return formData;
}
const getChapters = async (data) => {
  const response = await $authApi.get(apiRoutes.CHAPTERS, {
    params: data,
  });
  return response?.data;
};
const getChapter = async (id) => {
  const response = await $authApi.get(apiRoutes.CHAPTER, {
    params: {
      id,
    },
  });
  return response?.data;
};
const editChapter = async (data) => {
  const response = await $authApi.put(apiRoutes.CHAPTERS, data);
  return response?.data;
};
const uploadChapter = async (data) => {
  const response = await $authApi.post(apiRoutes.CHAPTERS_UPLOAD, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response?.data;
};
const deleteChapter = async (id) => {
  const response = await $authApi.delete(apiRoutes.CHAPTERS, {
    data: { id },
  });
  return response?.data;
};
const createChapter = async (data) => {
  const response = await $authApi.post(apiRoutes.CHAPTERS, data);
  return response?.data;
};
export {
  getChapters,
  uploadChapter,
  getChapter,
  editChapter,
  deleteChapter,
  createChapter,
};
