import moment from "moment";
import React, {
  createRef,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { Badge, Col, Row } from "react-bootstrap";
import {
  IoCloseOutline,
  IoSearchOutline,
  IoTrashOutline,
} from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { useSearchParams } from "react-router-dom";
import DataTable from "../../components/DataTable";
import Meta from "../../components/Meta";
import Button from "../../components/UI/Button";
import Input from "../../components/UI/Input";
import Loader from "../../components/UI/Loader";
import Select from "../../components/UI/Select";
import CustomModal from "../../components/utils/CustomModal";
// import { getImageURL } from "../../helpers/image";
import { customPrice } from "../../helpers/product";
import { getCategories } from "../../services/category";
import { deleteProduct, getProducts } from "../../services/product";

const Params = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const inputRef = createRef();

  const [params, setParams] = useState({
    loading: true,
    items: [],
  });
  const [categories, setCategories] = useState({
    loading: true,
    items: [],
  });

  const [selected, setSelected] = useState([]);
  const [modalDelete, setModalDelete] = useState({
    show: false,
    id: false,
  });

  const paramColumns = [
    {
      name: "Название",
      selector: "title",
    },
    {
      name: "Категория",
      selector: "category",
      cell: (row) => row?.category?.title ?? null,
    },
    {
      name: "Стоимость",
      selector: "price",
      width: 100,
      cell: (row) =>
        row?.modifiers?.length > 0
          ? "от " +
            customPrice(
              Math.min.apply(
                null,
                row.modifiers.map((e) => e.price)
              )
            )
          : customPrice(row.price),
    },
    {
      name: "Дата",
      cell: (row) => moment(row.createdAt).fromNow(),
      width: 150,
    },
    {
      name: "Статус",
      cell: (row) =>
        row.status ? (
          <Badge bg="success">Активно</Badge>
        ) : (
          <Badge bg="secondary">Архив</Badge>
        ),
      width: 100,
    },
    {
      width: "60px",
      selector: "action",
      align: "right",
      cell: (row) => (
        <div className="d-flex align-items-center">
          {/* <Link
            disabled={selected.length > 0}
            to={"/catalog/param/" + row.id}
            className="me-3"
          >
            <IoCreateOutline size={22} />
          </Link> */}
          <a
            className="me-3"
            disabled={selected.length > 0}
            onClick={() =>
              setModalDelete({ show: !modalDelete.show, id: row.id })
            }
          >
            <IoTrashOutline size={20} className="text-danger" />
          </a>
        </div>
      ),
    },
  ];

  const getData = useCallback(async () => {
    getProducts(searchParams)
      .then(
        (res) =>
          res &&
          setParams((prev) => ({
            ...prev,
            loading: false,
            ...res,
          }))
      )
      .finally(() => setParams((prev) => ({ ...prev, loading: false })));
  }, [searchParams]);

  const onDelete = useCallback((id) => {
    deleteProduct(id)
      .then(() => {
        getData();
        NotificationManager.success("Товар успешно удален");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, []);

  const onDeleteSelected = useCallback(() => {
    deleteProduct(selected.map((e) => e.item.id))
      .then(() => {
        setSelected([]);
        getData();
        NotificationManager.success("Выбранные товары успешно удалены");
        setModalDelete({ show: false, id: false });
      })
      .catch(() => NotificationManager.error("Ошибка при запросе"));
  }, [selected]);

  const onSearch = useCallback(() => {
    getData();
  }, [searchParams]);

  useLayoutEffect(() => {
    getCategories(1, 200)
      .then((res) => {
        if (res?.items?.length > 0) {
          let array = res.items.map((e) => ({ title: e.title, value: e.id }));
          setCategories((prev) => ({
            ...prev,
            loading: false,
            items: array,
          }));
        }
      })
      .finally(() => setCategories((prev) => ({ ...prev, loading: false })));
  }, []);

  useLayoutEffect(() => {
    getData();
  }, [searchParams.get("page")]);

  const header = useMemo(() => {
    return (
      <>
        <div className="d-flex align-items-center justify-content-between">
          <div>
            <h5 className="fw-7">
              {selected.length > 0
                ? `Выбрано ${selected.length}`
                : "Объявления"}
            </h5>
          </div>
          <div>
            <Button
              disabled={selected.length === 0}
              className="btn-light"
              onClick={() => setModalDelete({ show: true, id: false })}
            >
              <IoTrashOutline size={18} />
            </Button>
          </div>
        </div>
        <div>
          <Row>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Статус"
                data={[
                  { title: "Все", value: "" },
                  { title: "Заблокированные", value: "blocked" },
                  { title: "Подтвержденные", value: "verified" },
                ]}
                value={searchParams.get("status") ?? ""}
                onClick={(e) => {
                  searchParams.set("status", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={3}>
              <Select
                classNameContainer="w-100"
                label="Сортировка"
                data={[
                  { title: "По дате регистрации: новые", value: "datenew" },
                  { title: "По дате регистрации: старые", value: "dateold" },
                  { title: "Комиссия: больше", value: "commissionmore" },
                  { title: "Комиссия: меньше", value: "commissionless" },
                  { title: "Объявления: больше", value: "adsmore" },
                  { title: "Объявления: меньше", value: "adsless" },
                ]}
                value={searchParams.get("sort") ?? "datenew"}
                onClick={(e) => {
                  searchParams.set("sort", e.value);
                  setSearchParams(searchParams);
                  onSearch();
                }}
              />
            </Col>
            <Col md={6}>
              <Input
                ref={inputRef}
                placeholder="Найти"
                className="w-100"
                onChange={(e) => {
                  searchParams.set("text", e);
                  setSearchParams(searchParams);
                }}
                rightIcon={() => <IoSearchOutline size={22} />}
                defaultValue={searchParams.get("text")}
                rightIconClick={() => onSearch()}
                onKeyDown={(e) => e === "Enter" && onSearch()}
              />
              {searchParams.get("text")?.length > 0 && (
                <Button
                  className="btn-light ms-3"
                  onClick={() => {
                    searchParams.delete("text");
                    setSearchParams(searchParams);
                    onSearch();
                    if (inputRef.current) {
                      inputRef.current.value = "";
                    }
                  }}
                >
                  <IoCloseOutline size={22} />
                </Button>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }, [selected, searchParams, modalDelete]);

  if (params?.loading) {
    return <Loader full />;
  }

  return (
    <>
      <Meta title="Объявления" />
      <DataTable
        columns={paramColumns}
        onChange={(items) => setSelected(items)}
        data={params.items}
        header={header}
        selectable
        pagination={params.pagination}
      />
      <CustomModal
        title={
          selected.length > 0
            ? `Удаление ${selected.length} элементов`
            : "Удаление элемента"
        }
        show={modalDelete.show}
        setShow={(e) => setModalDelete({ show: e, id: false })}
        footer={
          <>
            <Button
              className="me-3"
              onClick={() =>
                setModalDelete({ show: !modalDelete.show, id: false })
              }
            >
              Отмена
            </Button>
            <Button
              className="btn-danger"
              onClick={() =>
                selected.length > 0
                  ? onDeleteSelected()
                  : modalDelete.id && onDelete(modalDelete.id)
              }
            >
              Удалить
            </Button>
          </>
        }
      >
        Вы точно хотите удалить объявление?
      </CustomModal>
    </>
  );
};

export default Params;
