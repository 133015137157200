import React, { useCallback, useState, useLayoutEffect } from "react";
import { Col, Row, Form } from "react-bootstrap";
import { useForm, useWatch } from "react-hook-form";
import { IoChevronBackOutline } from "react-icons/io5";
import { NotificationManager } from "react-notifications";
import { Link, useNavigate, useParams } from "react-router-dom";
import Meta from "../../../components/Meta";
import Button from "../../../components/UI/Button";
import Info from "../../../components/UI/Info";
import Input from "../../../components/UI/Input";
import CustomEditor from "../../../components/editor/CustomEditor";
import { createChapter } from "../../../services/chapter";
import { getBook } from "../../../services/book";
import Loader from "../../../components/UI/Loader";

const ChapterCreate = () => {
  const { bookId } = useParams();
  const [book, setBook] = useState({ loading: true });
  const navigate = useNavigate();
  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    reValidateMode: "onSubmit",
    defaultValues: {
      bookId,
    },
  });

  const data = useWatch({ control });

  const onSubmit = useCallback(() => {
    createChapter(data)
      .then((res) => {
        navigate("/catalog/book/" + bookId + "/chapter/" + res.id);
        NotificationManager.success("Глава успешно опубликована");
      })
      .catch(
        (err) => err && NotificationManager.error("Ошибка при сохранении")
      );
  }, [data]);

  useLayoutEffect(() => {
    getBook(bookId)
      .then((res) =>
        setBook((prev) => ({
          ...prev,
          ...res,
          loading: false,
        }))
      )
      .finally(() =>
        setBook((prev) => ({
          ...prev,
          loading: false,
        }))
      );
  }, []);

  if (book?.loading) {
    return <Loader full />;
  }

  if (!data) {
    return (
      <Info>
        <svg
          className="mb-3"
          width="60"
          height="60"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            opacity="0.32"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12 22.5C17.799 22.5 22.5 17.799 22.5 12C22.5 6.20101 17.799 1.5 12 1.5C6.20101 1.5 1.5 6.20101 1.5 12C1.5 17.799 6.20101 22.5 12 22.5ZM12 18.3C15.4794 18.3 18.3 15.4794 18.3 12C18.3 8.52061 15.4794 5.7 12 5.7C8.52061 5.7 5.7 8.52061 5.7 12C5.7 15.4794 8.52061 18.3 12 18.3Z"
            fill="#999"
          />
          <path
            d="M18.6028 3.01136C19.2179 2.39628 20.2151 2.39628 20.8302 3.01136C21.4453 3.62643 21.4453 4.62367 20.8302 5.23874L5.2385 20.8304C4.62342 21.4455 3.62619 21.4455 3.01111 20.8304C2.39604 20.2154 2.39604 19.2181 3.01111 18.6031L18.6028 3.01136Z"
            fill="#999"
          />
        </svg>
        <h3>Такого документа нет</h3>
      </Info>
    );
  }

  return (
    <>
      <Meta title={data.title ?? "Глава"} />
      <div className="d-flex justify-content-between align-items-center">
        <Link
          to={"/catalog/book/" + data.bookId}
          className="d-inline-flex align-items-center mb-3 fs-09 text-muted"
        >
          <IoChevronBackOutline className="me-2" size={18} /> Назад к списку
        </Link>
        <Button onClick={() => onSubmit()}>Сохранить изменения</Button>
      </div>
      <h3 className="mb-4">Создать главу</h3>
      <div className="mb-3">
        <Input
          label="Название"
          name="title"
          errors={errors}
          register={register}
          validation={{
            required: "Обязательное поле",
          }}
        />
      </div>
      <Row>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={0}
              type="number"
              label="Цена"
              name="price"
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={0}
              type="number"
              label="Скидка"
              name="discount"
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              type="datetime-local"
              label="Анонс"
              name="start"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              defaultValue={1}
              type="number"
              label="Статус"
              name="status"
              errors={errors}
              register={register}
              validation={{
                required: "Обязательное поле",
              }}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input
              type="number"
              label="Порядок"
              name="priority"
              errors={errors}
              register={register}
            />
          </div>
        </Col>
        <Col md={6}>
          <div className="mb-3">
            <Input label="Тип" name="type" defaultValue={book.type} />
          </div>
        </Col>
      </Row>
      <CustomEditor
        content={data.content}
        onChange={(e) => setValue("content", e)}
      />
    </>
  );
};

export default ChapterCreate;
